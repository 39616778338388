import Splide from '@splidejs/splide';

export default function initSplide() {
	bindDefaultSplideInstances();
}

function bindDefaultSplideInstances() {
	var splide = new Splide( '.splide', {
		type   : 'loop',
		perPage: 1,
		perMove: 1,
		arrows: false,
		autoplay: true,
		interval: 4000,
		type: 'fade',
	} );

	splide.mount();
}
