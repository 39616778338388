// Bootstrap

import "bootstrap/js/dist/base-component";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/offcanvas";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/scrollspy";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/toast";
import "bootstrap/js/dist/tooltip";

// var tooltipTriggerList = [].slice.call(
// 	document.querySelectorAll('[data-bs-toggle="tooltip"]')
// );
// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
// 	return new bootstrap.Tooltip(tooltipTriggerEl);
// });

// var popoverTriggerList = [].slice.call(
// 	document.querySelectorAll('[data-bs-toggle="popover"]')
// );
// var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
// 	return new bootstrap.Popover(popoverTriggerEl);
// });

// Custom

import initSplide from './splide';
initSplide();
